import { HubspotPropertyRecord } from '@/account/api/types.ts';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  TextField,
  Typography,
} from '@mui/material';
import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

/**
 * Returns true when the option item is equal to the current option value
 * @param option
 * @param value
 */
function isOptionEqualToValue(
  option: HubspotPropertyRecord,
  value: HubspotPropertyRecord
) {
  return option.name === value.name;
}

/**
 * Returns a group label for a company option.
 *
 * @param option - A company option to get the group label from.
 */
function getGroupLabel(option: HubspotPropertyRecord) {
  return option.categoryLabel;
}

/**
 * Renders a single company option.
 *
 * @param props - The HTML attributes to apply to the list item.
 * @param option - A company option to render.
 */
function renderOption(
  props: HTMLAttributes<HTMLLIElement>,
  option: HubspotPropertyRecord
) {
  return (
    <li {...props} key={option.name}>
      <Typography>{option.label}</Typography>
    </li>
  );
}

/**
 * Returns the label of the option.
 *
 * @param option - A company option to get the label from.
 */
function getOptionLabel(option: HubspotPropertyRecord) {
  return option.label;
}

interface Props<Option extends HubspotPropertyRecord>
  extends Omit<
    AutocompleteProps<Option, false, false, false>,
    | 'renderInput'
    | 'isOptionEqualToValue'
    | 'getOptionLabel'
    | 'groupBy'
    | 'renderOption'
  > {
  /**
   * The name of the form input element.
   */
  name?: string;

  /**
   * The label attached to the input element.
   */
  label?: string;

  /**
   * If true, the component will be displayed in an error state.
   */
  error?: boolean;

  /**
   * If provided, the helper text will be displayed below the input element.
   */
  helperText?: string;

  /**
   * If provided, the placeholder text will be displayed in the input element.
   */
  placeholder?: string;
}

/**
 * Renders a CRM property selector
 */
function CRMPropertySelectorRenderer<Option extends HubspotPropertyRecord>(
  props: Props<Option>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { name, label, placeholder, error, helperText, ...autocompleteProps } =
    props;

  const renderInput = (parameters: AutocompleteRenderInputParams) => {
    return (
      <TextField
        inputRef={ref}
        name={name}
        label={label}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        {...parameters}
      />
    );
  };

  return (
    <Autocomplete
      size="small"
      {...autocompleteProps}
      groupBy={getGroupLabel}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}

/**
 * An autocomplete component for selecting a company from a list of options.
 */
export const CRMPropertySelector = forwardRef(CRMPropertySelectorRenderer);
